import { h } from "preact";
import { route } from "preact-router";
import { useState, useEffect } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailIcon from "@material-ui/icons/Mail";
import Badge from "@material-ui/core/Badge";
import style from "./style.css";
import Notify from "./notify";
import Menu from "@material-ui/core/Menu";
import { Paper, Tooltip } from "@material-ui/core";

const DropDown = ({ anchorEl, logout, close, userData }) => {
	return (
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={close}
			onClick={close}
			elevation={8}
			getContentAnchorEl={null}
			autoFocus={false}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			PaperProps={{
				style: {
					transform: "translateX(15px) translateY(20px)",
				},
			}}
			MenuListProps={{
				style: {
					paddingRight: 100,
				},
			}}
		>
			<div class={"flex-row " + style.list}>
				<div class="column" style="margin-right:3vw">
					<Tooltip
						title={<div className={style.tooltip}>{userData?.Username}</div>}
						placement="right"
					>
						<span className={style.username}>{userData?.Username}</span>
					</Tooltip>
					<span>{userData?.Account}</span>
					<span onClick={() => route("/settings/accountSettings")}>帳戶設定</span>
				</div>
				<span onClick={() => logout()} style={{transform: "translateX(75px) translateY(5px)"}}>登出</span>
			</div>
		</Menu>
	);
};

const Header = ({ pathName, userData }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setAnchorEl(null);
	}, [pathName]);

	const logout = () => {
		setAnchorEl(null);
		localStorage.removeItem("token");
		route("/login", true);
	};

	const handleRoute = () => {
		let token = localStorage.getItem("token");
		if (token) {
			route("/", true);
		}
	};

	return (
		<header class={style.header}>
			<div class={style.left}>
				<span style={{ fontSize: "2rem" }}>
					<img
						style={{width: "58%", paddingLeft: "15px"}}
						src="../../assets/logo/white_logo.png"
						onClick={() => handleRoute()}
					/>
				</span>
				{pathName !== "/login" ? (
					<div class="d-flex al-center">
						<SettingsIcon
							fontSize="large"
							style="margin-right:1vw"
							onClick={() => route("/settings/machineSettings")}
						/>
						{localStorage.getItem("token") ? <Notify /> : <></>}
					</div>
				) : null}
			</div>

			{pathName !== "/login" ? (
				<div class={style.right} onClick={(e) => handleClick(e)}>
					<AccountCircleIcon fontSize="large" style="margin-right:1vw" />
					<Tooltip
						title={<div className={style.tooltip}>{userData ? userData.Username : "User"}</div>}
						placement="bottom"
					>
						<span className={style.username}>{userData ? userData.Username : "User"}</span>
					</Tooltip>
					<ExpandMoreIcon />
				</div>
			) : null}
			<DropDown anchorEl={anchorEl} logout={logout} close={handleClose} userData={userData} />
		</header>
	);
};

export default Header;
