import { h } from "preact";
import { Link } from "preact-router/match";
import { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { SocketOn } from '../../components/socketApi';
import { apiPostNotifyReceive, apiGetUserData } from '../../components/api';

const Notify = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [msg, setMsg] = useState([]);
  const [notReceiveCount, setNotReceiveCount] = useState(0);
  const ws = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickReceive = (index) => {
    apiPostNotifyReceive({receivedID: msg[index].id})
      .then(res => {
          msg[index].receive = 1;
          setMsg(msg);
          const newCount = msg.filter(ele => ele.receive === 0).length;   
          setNotReceiveCount(newCount);           
      }) 
      .catch(e => console.error(e));
  }

  const handleClickAllReceive = () => {
    const idList = msg.map(item => item.id);
    apiPostNotifyReceive({receivedID:idList})
        .then(res => {
            const newMsg = msg.map(ele => {
              ele.receive = 1;
              return ele;
            });
            setMsg(newMsg);
            const newCount = msg.filter(ele => ele.receive === 0).length;   
            setNotReceiveCount(newCount);           
        }) 
        .catch(e => console.error(e));
  }  

  useEffect(async () => {
    const user = await apiGetUserData();
    const emitData = JSON.stringify({ userID: user.data.ID });

    ws.getNotify = new SocketOn('getNotify', emitData);
    ws.getNotify.onEvent = (data) => {
      const list = data.userNotify;
      console.log(list);
      const newCount = list.filter(ele => ele.receive === 0).length;
      setMsg(list);
      setNotReceiveCount(newCount);
    }
    ws.getNotify.run();
    return () => {
      ws.getNotify.offEvent();
    };
  }, []);

  return(
      <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{color : '#fff'}}>
          <Badge badgeContent={notReceiveCount} color="error">
            <MailIcon fontSize="large" />
          </Badge>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight:  300,
              width: '300px',
            },
          }}          
        >
            <MenuItem style=" justify-content: space-between;">
              <h3>
                通知
              </h3>                  
              <Button  onClick={handleClickAllReceive} variant="contained">
                全部標記為已讀
              </Button>                      
            </MenuItem>            
            {
                msg.length > 0 ? 
                    msg.map((item, i) => {
                        return(
                            <MenuItem onClick={() => handleClickReceive(i)} style={{background : item.receive === 0 ? '#ccc' : ''}}>
                                <div>
                                    {item.title}
                                    <br/>
                                    {item.message}   
                                    <br/>
                                    {moment(item.createTime).format('MM/DD HH:mm') }                                 
                                </div>
                            </MenuItem>
                        );
                    })
                    :
                    <MenuItem>
                      無新訊息
                    </MenuItem>
            }
        </Menu> 
      </div>
  );
};

export default Notify;
