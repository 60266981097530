import axios from "axios";
const baseUrl = process.env.PREACT_APP_BASE_API;

export const request = axios.create({
	baseURL: baseUrl,
	transformRequest: [
		(data, headers) => {
			headers.Authorization = localStorage.getItem("token");
			return data;
		},
		...axios.defaults.transformRequest,
	],
});

//user
export const apiPostLogin = (data) => request.post("/user/login", data);

export const apiPostCreate = (data) => request.post("/user/create", data);

export const apiGetUserData = () => request.get("/user/self");

export const apiUpdateUser = (data) => request.put("/user/update", data);

export const apiUpdatePassword = (data) => request.put("/user/update/password", data);

export const apiPostResetPassword = (data) => request.post("/user/reset", data);

export const apiGetResetVerify = (token) => request.get(`/user/reset/verify/${token}`);

export const apiPostResetNewPassword = (token, data) => request.post(`/user/reset/${token}`, data);

//home
export const apiGetMachineList = (data) => request.get("/machineData", { params: data });

export const apiPostNotifyReceive = (data) => request.post(`/notify/receive`, data);

//machine

export const apiGetOee = (data) => request.get("/oee", { params: data });

export const apiGetLiveIPCam = (data) => request.get("/device_info/ipcam/live", { params: data });

export const apiGetErrorRecord = (data) =>
	request.get(`/device_info/error_record`, { params: data });

export const apiGetErrorPlayback = (data) =>
	request.get(`/device_info/error_record/playback`, { params: data });

//line

export const apiGetLine = (data) => request.get("/line", { params: data });

export const apiPostLine = (data) => request.post("/line", data);

export const apiPutLine = (id, data) => request.put("/line/" + id, data);

export const apiPostSequenceLine = (data) => request.post("/line/sequence", data);

export const apiDeleteLine = (id, data) => request.delete("/line/" + id, { params: data });

//device

export const apiGetDeviceSerialNumber = (data) =>
	request.get("/device/serial_number", { params: data });

export const apiGetDeviceType = () => request.get("/device/type");

export const apiPostDevice = (data) => request.post("/device", data);

export const apiPostDeviceSerialNumber = (data, query) =>
	request.post("/device/serial_number", data, { params: query });

export const apiGetDevice = (data) => request.get("/device", { params: data });

export const apiGetAllDevice = (data) => request.get("/device/list", { params: data });

export const apiPutDevice = (serialNumber, data) => request.put(`/device/${serialNumber}`, data);

export const apiPostSequenceDevice = (data) => request.post("/device/sequence", data);

export const apiDeleteDevice = (id, data) => request.delete(`/device/${id}`, { params: data });

//company
export const apiGetCompany = (id) => request.get(`/company/${id}`);

export const apiGetCompanyUsers = (id) => request.get(`/company/${id}/user`);

export const apiGetCompanyList = () => request.get("/company");

export const apiPostCompany = (data) => request.post("/company", data);

export const apiPutCompany = (id, data) => request.put(`/company/${id}`, data);

//mail
export const apiGetNoticeMail = (params) => request.get("/noticeMail", { params });

export const apiSendNoticeMail = (data, params) =>
	request.post("/noticeMail/send", data, { params });

//logo
export const apiGetLogo = (params) => request.get("/logo", { params });

export const apiPostLogo = (data) => request.post("/logo", data);

export const apiDeleteLogo = (data) => request.delete("/logo", { data });
