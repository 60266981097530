import { h } from "preact";
import { useState, useEffect } from 'preact/hooks';
import { Router, route } from "preact-router";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import Header from "./header";
// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Machine from "../routes/machine";
import MachineDCM from "../routes/machineDCM";
import Login from "../routes/login";
import Signup from "../routes/signup";
import Settings from "../routes/settings";
import {apiGetUserData} from './api';


const App = () => {
  const [ token, setToken ] = useState()
  const [ pathName , setPathName ] = useState(false)
  const [ userData , setUserData ] = useState([])
  const fpPromise = FingerprintJS.load()

  // once you open this project, you create a token
  fpPromise.then(fp => fp.get().then(result => {
    setToken(result.visitorId)
  }))

  const refresh = async () => {
    const res = await apiGetUserData()
                        .catch(() => {
                          alert("已在多於兩部裝置登入，此帳號將被登出。")
                          localStorage.removeItem("token");
                          handleRoute();
                        });
    if (res) {
      setUserData(res.data);
      localStorage.setItem('CompanyID', res.data.CompanyID);
    }
  }

  useEffect(() => {
    refresh();
  },[])

  //create token need times, useEffect is to detect wether token have been generated or not
  useEffect(() => {
    localStorage.setItem('deviceToken', token);
  },[token])

  const handleRoute = () => {
    //PathName is also used in header component to close dropdown and block icons
    if(window.location){
      setPathName(window.location.pathname);
    }

    if(!localStorage.getItem('token'))
      route('/login',true)
  }

  return(
    <div id="app">
      <Header pathName={pathName} userData={userData}/>
      <Router onChange={() => handleRoute()}>
        <Home path="/" userData={userData}/>
        <Machine path="/machine/:machine_number" userData={userData} />
        <MachineDCM path="/machineDCM/:machine_number" userData={userData} />
        <Login path="/login" refresh={refresh}/>
        <Signup path="/signup" />
        <Settings path="/settings/:rest*" userData={userData} refresh={refresh}/>
      </Router>
    </div>
  )
}

export default App;
