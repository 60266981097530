import { io } from "socket.io-client";

const socket = io(process.env.PREACT_APP_BASE_API_WS || 'ws://ec2-54-199-106-21.ap-northeast-1.compute.amazonaws.com:1337');

export class SocketOn{
    constructor(eventName, initEmitData){
        this.eventName = eventName;
        this.initEmitData = initEmitData;
    }

    offEvent(){
        socket.off('connect');
        socket.off(`${this.eventName}:success`);
        socket.off(`${this.eventName}:err`);
        socket.emit(`${this.eventName}:clear`);
    }

    onEvent(data){}

    run(){
        socket.emit(this.eventName, this.initEmitData);
        this.onConnect = socket.on('connect', () => {
            console.log(`${this.eventName} socket connect success`);
        });

        socket.on(`${this.eventName}:success`, (res) => {
            const data = JSON.parse(res);
            this.onEvent(data);
        });

        socket.on(`${this.eventName}:err`, (err) => {
            console.log(`${this.eventName}:err`,err)
        });   
    }

    emit(emitData){
        socket.emit(this.eventName, emitData);
    }
}
